import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Grid } from "@mui/material"
import { graphql, Link } from "gatsby"
import Sidebar from "./sidebar"
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm"
import { formatDate, getImageURL } from "../format-date"
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight"
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import back from "../images/icons/back.png";
import front from "../images/icons/front.png"
import menubar from '../images/icons/white-menu.png'
import getlogo from '../images/icons/logo.png'
import copy from '../images/icons/copy.png'
const SubCategoryDocumentData = ({data}) => {
  data.strapiDocuments.srcset = "";
    data.strapiDocuments.sizes = "";
    data.strapiDocuments.srcset += `${getImageURL(data.strapiDocuments.banner_image.localFile.name, data.strapiDocuments.banner_image.localFile.extension, 300)} 300w,`;
    data.strapiDocuments.srcset += `${getImageURL(data.strapiDocuments.banner_image.localFile.name, data.strapiDocuments.banner_image.localFile.extension, 450)} 450w,`;
    data.strapiDocuments.srcset += `${getImageURL(data.strapiDocuments.banner_image.localFile.name, data.strapiDocuments.banner_image.localFile.extension, 500)} 600w,`;
    data.strapiDocuments.srcset += `${getImageURL(data.strapiDocuments.banner_image.localFile.name, data.strapiDocuments.banner_image.localFile.extension, 600)} 600w,`;
    data.strapiDocuments.srcset += `${getImageURL(data.strapiDocuments.banner_image.localFile.name, data.strapiDocuments.banner_image.localFile.extension, 800)} 800w,`;
    data.strapiDocuments.srcset += `${getImageURL(data.strapiDocuments.banner_image.localFile.name, data.strapiDocuments.banner_image.localFile.extension, 1200)} 1200w,`;
    data.strapiDocuments.sizes += `(max-width: 1200px) 40vw, 1200px`;
  let categories = data.allStrapiCategories.nodes;
    const subCategories = data.allStrapiSubCategories.nodes;
    categories.forEach(category => {
      const subCategoryList = subCategories.filter(subCategory => subCategory.category.category_slug === category.category_slug);
      if(subCategoryList.length){
        category.subCategories = subCategoryList;
        category.showSubCategory = false;
      }
    });
      function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer" className="anchor-tag">
                {props.children}
            </a>
        );
    }
    function addImageLazyLoading(props){
      return (
        <img srcSet={props.src} alt={props.alt} loading="lazy"></img>
      )
    }
      function highLightCodeSyntax(props) {
        return (
          <>
          <SyntaxHighlighter language="dart" style={atomOneDark}>
            {props.children[0].props.children[0]}
          </SyntaxHighlighter>
          <img src={'https://ik.imagekit.io/ionicfirebaseapp/copy_909hwRIAt.png'} loading="lazy"  className=" copy-icon" onClick={() => copyTextToClipboard(props.children[0].props.children[0])} />

          </>
        );
    }
    function copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        console.log("Could not copy",text);
        return;
      }
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }
    let seoData;
    if(data.strapiDocuments.sub_category){
    seoData = {
      title:data.strapiDocuments.sub_category.seo_title,
      description:data.strapiDocuments.sub_category.seo_description,
      imageUrl:getImageURL(data.strapiDocuments.sub_category.seo_image.localFile.name,data.strapiDocuments.sub_category.seo_image.localFile.extension),
      route:`/${data.strapiDocuments.category.category_slug}/${data.strapiDocuments.sub_category.sub_category_slug}/`
    }
  }
  return (
  <Layout>
    {seoData ? <Seo metaInfo={seoData} /> : ''}
    <div className="header-class">
   <ul className="header-ul">
     <li className="logo">
       <Link to="/"> <img src={'https://ik.imagekit.io/ionicfirebaseapp/getwidget1_aFuUVzToL.png'} className=" logoimg"/> </Link>
    
     </li>
     <li>
       <div className="li-links">
         <ul>
           <li><Link to="/">Home</Link></li>
          <li><Link to="https://www.getwidget.dev/blog/" target="_blank">Blog</Link> </li>
     <li><Link to="https://github.com/ionicfirebaseapp/getwidget" target="_blank">Github</Link> </li>
     <li><Link to="https://market.getwidget.dev/products/" target="_blank">Flutter App Kit</Link></li>
         </ul>
       </div>
        </li>
     

   </ul>
   </div>

   <div className="mobile-header">
     <div className="container-fluid">
       <div className="row">
         <div className="col-md-2 col-2 pt-2">
         <nav className="mobile-links">
       
       <label htmlFor="menu-checkbox" className="menu-btn">
        
           <input
           type="checkbox"
           id="menu-checkbox"
           />
         
           <div className="hamburger-menu">
           <img src={menubar} className="menu-img"/> 
           </div>
        
           <ul className="menu-items">
           {categories.map(category => (
               category.subCategories ? <div className="submenu-ul" key={category.category_slug}>
               <ul className="submenu-ul-list">
                 <li><Link to={`/${category.category_slug}/`} activeClassName="activeRoute">{category.category_name}</Link></li>
                 <div className="submenu-items">
                   <ul>
                     {category.subCategories.map(subCategory => (
                       <li><Link to={`/${category.category_slug}/${subCategory.sub_category_slug}/`} key={subCategory.sub_category_slug} activeClassName="activeRoute">{subCategory.sub_category_name}</Link></li>
                     ))}
                   </ul>
                 </div>
               </ul>
  
             </div> :
              <li key={category.category_slug}>
                <Link to={`/${category.category_slug}/`} activeClassName="activeRoute">{category.category_name}</Link>
              </li>
             ))}
           </ul>
       </label>
   
   </nav>
         </div>
     
       
         <div className="col-md-6 col-8 pt-2 pb-2">
         <div className="logo-name">
         <Link to="/"> <img src={'https://ik.imagekit.io/ionicfirebaseapp/getwidget1_aFuUVzToL.png'} loading="lazy"  className=" logoimg"/> </Link>
         </div>
         </div>
      
       </div>
     </div>

    </div>
 <div className="main-content">
  
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-2 sidebar-bg">
      <Sidebar  />  
      </div>
      <div className="col-md-6 offset-md-2">
      <div className="scrollable-block">
      {data.strapiDocuments.page_title ? <h1>{data.strapiDocuments.page_title}</h1> : ''}
        {data.strapiDocuments.banner_image ? <img srcSet={data.strapiDocuments.srcset}  sizes={data.strapiDocuments.sizes} style={{"width":"100%","height":"100%"}} alt={data.strapiDocuments.page_title} /> : ''}
        <ReactMarkdown remarkPlugins={[remarkGfm]} children={data.strapiDocuments.page_info} components={{a:LinkRenderer,pre:highLightCodeSyntax,img:addImageLazyLoading}} />
        <div className="row last-update-row">
          <div className="col-md-12">
          <div className="last-updated"><p>Last Updated: {formatDate(data.strapiDocuments.updated_at)}</p></div>
          </div>
        </div>
       
        <hr/>


<div className="container-fluid">
  <div className="row">
    <div className="col-md-5">
    {data.strapiDocuments.prev_document_category ? <div className="prev">
            <img src={back} className="img-fluid prev-icon"/>
            <Link to={`/${data.strapiDocuments.prev_document_category.category_slug}/`}>
              <h5>{data.strapiDocuments.prev_document_category.category_name}</h5>
            </Link>
            </div> : ''}
            {data.strapiDocuments.prev_document_sub_category ? <div className="prev">
            <img src={back} className="img-fluid prev-icon"/>
            <Link to={`/${data.strapiDocuments.category.category_slug}/${data.strapiDocuments.prev_document_sub_category.sub_category_slug}/`}>
              <h5>{data.strapiDocuments.prev_document_sub_category.sub_category_name}</h5>
            </Link>
            </div> : ''}
    </div>
    <div className="col-md-5 offset-md-2">
    {data.strapiDocuments.next_document_category ? <div className="next">
              <Link to={`/${data.strapiDocuments.next_document_category.category_slug}/`}>
                 <h5 className="next-arrow">{data.strapiDocuments.next_document_category.category_name}</h5>
              </Link>
              <img src={front} className="img-fluid prev-icon"/>
            </div> : ''}
            {data.strapiDocuments.next_document_sub_category ? <div className="next">
              <Link to={`/${data.strapiDocuments.category.category_slug}/${data.strapiDocuments.next_document_sub_category.sub_category_slug}/`}>
                 <h5 className="next-arrow">{data.strapiDocuments.next_document_sub_category.sub_category_name}</h5>
              </Link>
              <img src={front} className="img-fluid prev-icon"/>
            </div> : ''}
    </div>
  </div>
</div>
     
      </div>
      </div>
    </div>
  </div>

 </div>
   
  </Layout>
)
} 

export default SubCategoryDocumentData

export const query = graphql`
query GetSubCategoryDocumentData($slug:String,$subCategorySlug:String) {
  strapiDocuments(category:{category_slug:{eq:$slug}},sub_category:{sub_category_slug:{eq:$subCategorySlug}}){
    page_info
    updated_at
    page_title
    banner_image{
      localFile{
        name
        extension
      }
    }
    sub_category{
      seo_title
      seo_description
      seo_image{
        localFile{
          name
          extension
        }
      }
      sub_category_name
      sub_category_slug
    }
    category{
        category_slug
    }
    next_document_sub_category{
      sub_category_name
      sub_category_slug
    }
    prev_document_sub_category{
      sub_category_name
      sub_category_slug
    }
    prev_document_category{
      category_name
      category_slug
    }
    next_document_category{
      category_name
      category_slug
    }
  }
  allStrapiCategories(sort:{order:ASC,fields:[sort_order]}) {
    nodes {
      category_slug
      category_name
    }
  }
  allStrapiSubCategories{
    nodes{
      sub_category_name
      sub_category_slug
      category{
        category_slug
      }
    }
  }
}
`